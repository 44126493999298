<template>
  <!-- Filter -->
  <v-card>
    <v-card-text class="d-flex justify-space-between">
      <div>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="tableList.paginate"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </div>

      <div>
        <create-dialog
          v-if="$can('distributor_team:create', 'distributor_team_management')"
          :distributor-id="distributorId"
          :disabled-fields="['distributor_id']"
          @created="tableList.paginate"
        />
      </div>
    </v-card-text>

    <team-table
      ref="tableRef"
      :table-list="tableList"
    />
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import { useTableList } from '@/composables'
import { mdiRefresh } from '@mdi/js'
import CreateDialog from '../components/team/CreateDialog.vue'
import TeamTable from '../components/team/TeamTable.vue'
import { teamService } from '../services'

export default {
  components: {
    TeamTable,
    CreateDialog,
  },
  props: {
    distributorId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const filters = ref({
      search: null,
      distributor_id: props.distributorId,
    })

    const tableList = useTableList(teamService, filters)

    const {
      tableRef,
      loading,
    } = tableList

    return {
      tableRef,
      loading,
      tableList,

      icons: {
        mdiRefresh,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
